import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Divider } from 'components/Divider';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { Introduction } from './helpers/Introduction';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Rhythm grouped mb={14} mt={12} mdxType="Rhythm">
  <Introduction mdxType="Introduction" />
    </Rhythm>
    <Typography size="6xlarge" weight="light" mdxType="Typography">
  Introduction
    </Typography>
    <Rhythm mb={4} mt={1} mdxType="Rhythm">
  <Divider mdxType="Divider" />
    </Rhythm>
    <p>{`Phork/it is a UI kit built with React and TypeScript. It provides a customizable and accessible library of components that can be used to speed up React application development.`}</p>
    <br />
    <br />
    <Typography size="6xlarge" weight="light" mdxType="Typography">
  Getting started
    </Typography>
    <Rhythm mb={4} mt={1} mdxType="Rhythm">
  <Divider mdxType="Divider" />
    </Rhythm>
    <p>{`Include the common styles and fonts in `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`import '@phork/phorkit/lib/styles/normalize.css';
import '@phork/phorkit/lib/styles/fonts.css';
import '@phork/phorkit/lib/styles/common.css';
`}</code></pre>
    <p>{`Include the basic providers in `}<inlineCode parentName="p">{`App.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`<ThemeProvider themeId={light|dark}>
  <AccessibilityProvider>
    <Modals>
      hello world
    </Modals>
  </AccessibilityProvider>
</ThemeProvider>
`}</code></pre>
    <Typography size="6xlarge" weight="light" mdxType="Typography">
  Themes
    </Typography>
    <Rhythm mb={4} mt={1} mdxType="Rhythm">
  <Divider mdxType="Divider" />
    </Rhythm>
    <p>{`Phork/it comes with a light theme and a dark theme. The primary accent color is a medium blue `}<inlineCode parentName="p">{`#0060ce`}</inlineCode>{` in light mode, and a deep sky blue `}<inlineCode parentName="p">{`#00baee`}</inlineCode>{` in dark mode.`}</p>
    <p>{`These accent colors can be changed globally or on a per-component basis using CSS custom properties. For example:`}</p>
    <pre><code parentName="pre" {...{}}>{`:root {
  --phork-accent-color: #642da0;
  --phork-accent-color-contrast: #ffffff;
  --phork-accent-color-L10: #803DC8;
  --phork-accent-color-L20: #9D69D5;
  --phork-accent-color-L30: #B995E1;
  --phork-accent-color-D10: #57278C;
  --phork-accent-color-D20: #4B2278;
  --phork-accent-color-D30: #3E1C64;
  --phork-accent-color-shade: #F3EFF8;
  --phork-accent-color-O5: rgba(100, 45, 160, 0.05);
  --phork-contrast-color: #642da0;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      